import { Loader, LineItem, Receipt } from "@unchained/component-library";

import { Invoice } from "Shared/api/v2";
import { usePurchasables } from "Shared/api/v2/hooks/purchasables";

export const InvoiceReceiptShow = ({
  invoice,
  applyCouponCode,
}: {
  invoice: Invoice;
  applyCouponCode: (v: string) => Promise<void> | void;
}) => {
  const purchablesQuery = usePurchasables();
  if (purchablesQuery.isLoading) {
    return <Loader />;
  }
  const lineItems = invoice.lineItems.map(item => {
    const purchasable = purchablesQuery.data.purchasables.find(p => p.sku === item.sku);
    if (!purchasable) {
      throw new Error(`Purchasable not found for SKU: ${item.sku}`);
    }

    const price = invoice.id ? parseFloat(item.usdPrice) : parseFloat(purchasable.usdPrice);
    return {
      title: purchasable.name,
      price,
      quantity: item.quantity,
      subtitle: purchasable.subscriptionInterval === "year" ? `Annual fee` : "",
    } as LineItem;
  });

  const subtotal = lineItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const discounts =
    invoice.discounts?.map(discount => {
      const isSignature = discount.name === "Signature";
      const title = isSignature ? "Signature Discount" : discount.name;
      const price =
        !discount.usdAmount && isSignature ? -subtotal : -parseFloat(discount.usdAmount);
      return {
        title,
        price,
        colorClass: isSignature ? "text-gold-600" : undefined,
      } as LineItem;
    }) || [];

  const displayAppliedCreditsAsDiscounts = () => {
    invoice.creditItems?.forEach(creditItem => {
      const purchasable = purchablesQuery.data.purchasables.find(p => p.sku === creditItem.sku);
      if (!purchasable) {
        return;
      }
      discounts.push({
        title: purchasable.name + " (One Time Credit)",
        price: -parseFloat(creditItem.usdAmount),
      });
    });
  };

  // Credit items are only present on completed invoices
  if (invoice.id) {
    displayAppliedCreditsAsDiscounts();
  }

  const taxItems = invoice.tax
    ? [{ title: "Tax", fixedPrice: parseFloat(invoice.tax) }]
    : undefined;

  return (
    <Receipt
      lineItems={lineItems}
      taxItems={taxItems}
      noTaxItemsLine="To be calculated"
      discountLineItems={discounts}
      applyCouponCode={applyCouponCode}
    />
  );
};
