import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";

import { DEFAULT_ORG_MENU_UUID_STORAGE_NAME } from "Actions/accountActions/constants";

type LocalStorageValue = "currentOrgUuid";
const values = {
  currentOrgUuid: {
    type: "string",
    // This is to accommodate the historical key constant we have for this item.
    // Not a pattern to continue.
    keyName: DEFAULT_ORG_MENU_UUID_STORAGE_NAME,
  },
} as Record<
  LocalStorageValue,
  { type: "string" | "integer" | "float" | "object"; keyName?: string }
>;

type ValueMap = {
  currentOrgUuid: string;
};

const valueKeys = Object.keys(values) as (keyof typeof values)[];

const LocalStorageContext = createContext<{
  values: Partial<Record<LocalStorageValue, string>>;
  setValues: (value: unknown) => void;
}>({
  values: {},
  setValues: () => {},
});

const setValueInLS = <K extends LocalStorageValue>(key: K, value: ValueMap[K]) => {
  const k = values[key].keyName || key;
  if (["integer", "float", "string"].includes(values[key].type)) {
    window.localStorage.setItem(k, value);
  } else {
    window.localStorage.setItem(k, JSON.stringify(value));
  }
};

const getValueInLs = <K extends LocalStorageValue>(key: K): ValueMap[K] => {
  const rawValue = window.localStorage.getItem(values[key].keyName);
  // TODO: Handle other types of LocalStorage value as they're used
  return rawValue;
};

/**
 * Set a value for a key in localStorage and the matching context.
 * Only LocalStorageValue keys are allowed.
 *  */
export const useLocalStorage = (valueName: LocalStorageValue) => {
  const { values, setValues } = useContext(LocalStorageContext);

  const value = getValueInLs(valueName);
  const setValue = (newValue: unknown) => {
    setValues({
      ...values,
      [valueName]: newValue,
    });
  };
  return { value, setValue };
};

/** A helper for retrieving the currentOrgUUID from the context. */
export const useCurrentOrgUuid = () => useLocalStorage("currentOrgUuid");

const getValuesInLs = () => Object.fromEntries(valueKeys.map(key => [key, getValueInLs(key)]));

const INTERVAL = 300;

/**
 * This Context allows us to treat localStorage as a context that can be hooked into.
 * Most notably, changing the localStorage value will update the context immediately,
 * so that you can, for example, set the current org ID in window.localStorage, and the
 * app will react to that change. This occurs via an interval, at a slight delay.
 *  */
export const LocalStorageProvider = ({
  children,
  debug,
}: {
  children: ReactNode;
  debug?: boolean;
}) => {
  const valuesInLs = getValuesInLs();
  const [values, setValues] = useState(valuesInLs);

  let interval = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    // Periodically, check if the values in localStorage have changed,
    // And set them to context if they have. There isn't a better way to do this
    // if we can't rely on setting localStorage exclusively through some helper that publishes
    // an event. We don't want to do that, because it's useful to set localStorage directly and
    // have the app react (in the dev console, in E2Es, etc.)
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      const newValues = getValuesInLs();
      if (JSON.stringify(newValues) === JSON.stringify(values)) return;
      if (debug)
        console.info("LocalStorageProvider - new values detected in local storage", {
          newValues,
          values,
        });
      setValues(newValues);
    }, INTERVAL);

    () => clearInterval(interval.current);
  }, [values, debug]);

  const value = useMemo(
    () => ({
      values,
      setValues: (newVals: Partial<Record<LocalStorageValue, string>>) => {
        if (debug)
          console.info("LocalStorageProvider - setting new values through hook", {
            newVals,
            values,
          });

        // Update the values individually in LS
        Object.keys(newVals).forEach((key: LocalStorageValue) => {
          const value = newVals[key];
          if (getValueInLs(key) !== value) {
            setValueInLS(key, value);
          }
        });
        // Then set the context to match
        setValues(newVals);
      },
    }),
    [values, debug]
  );

  return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>;
};
