import loadable from "@loadable/component";
import { Loader } from "@unchained/component-library";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import Protect from "Components/Protect";
import PublicApp from "Components/PublicApp";
import UserApp from "Components/UserApp";
import { LoadingContextProvider } from "Contexts/LoadingContext";
import ChangeProposalGroup from "Routes/change-proposals/[uuid]/ChangeProposalGroup";
import InvoiceStepper from "Routes/invoices/[uuid]/InvoiceStepper";
import { IRAConsentDash } from "Routes/onboard/[uuid]/(ira)/steps/Checkout/IRAConsent";

/*
 * Lazy loading is a design pattern that defers the loading of non-critical resources at page load time.
 * Instead, these resources are loaded when they are needed. This leads to a significant reduction in
 * initial load time and boosts performance by only loading what's necessary.
 *
 * We have implemented Loadable for lazy loading of our routes. This approach ensures that each route
 * only loads the components it needs, significantly reducing the initial load time and improving the
 * overall performance of our application.
 *
 * After evaluating both (React.lazy + React.Suspense) vs Loadable, we chose Loadable for the ability
 * to customize the fallback loader for each component specifically which provides a better UX.
 *
 * With React.Suspense we could not find define a fallback on a per lazy loaded component in this
 * file, and therefore the resulting UX was a full screen flash. With loadable
 * we can easily providing a smoother UX where the fallback Loader only appear in the area where the
 * component page component is swapping leaving for example the sidebar intact.
 *
 * It may be possible to achieve the same UX with React.Suspense but we could not find a way to do so by
 * by only modifying this file which is prefereable as it contains the lazy routing logic to one place.
 */

const AccountKeyNewWizard = loadable(() => import("Components/AccountKeys/AccountKeyNewWizard"), {
  fallback: <Loader className="h-screen" />,
});
const AdminListTrustOrgs = loadable(() => import("Routes/admin/orgs/trusts/AdminListTrusts"), {
  fallback: <Loader className="h-screen" />,
});
const AdminShowTrust = loadable(() => import("Routes/admin/orgs/trusts/[uuid]/AdminShowTrust"), {
  fallback: <Loader className="h-screen" />,
});
const Login = loadable(() => import("Components/Authentication/Login"), {
  fallback: <Loader className="h-screen" />,
});
const Logout = loadable(() => import("Components/Authentication/Logout"), {
  fallback: <Loader className="h-screen" />,
});
const Signup = loadable(() => import("Components/Authentication/Signup"), {
  fallback: <Loader className="h-screen" />,
});
const SignupConfirm = loadable(() => import("Components/Authentication/SignupConfirm"), {
  fallback: <Loader className="h-screen" />,
});
const ConfirmPasswordReset = loadable(
  () => import("Components/Authentication/password_resets/ConfirmPasswordReset"),
  { fallback: <Loader className="h-screen" /> }
);
const RequestPasswordReset = loadable(
  () => import("Components/Authentication/password_resets/RequestPasswordReset"),
  { fallback: <Loader className="h-screen" /> }
);
const BtcTransactionRequestsList = loadable(
  () => import("Components/BtcTransactionRequests/BtcTransactionRequestsList"),
  { fallback: <Loader className="h-screen" /> }
);
const Dashboard = loadable(() => import("Components/Dashboard/Dashboard"), {
  fallback: <Loader className="h-screen" />,
});
const DelegatedCustodyClientList = loadable(() => import("Components/DelegatedCustody/OrgList"), {
  fallback: <Loader className="h-screen" />,
});
const LoanNewContainer = loadable(() => import("Components/LoansView/NewFlow/LoanNewContainer"), {
  fallback: <Loader className="h-screen" />,
});
const LoanShowUser = loadable(() => import("Components/LoansView/Show/LoanShowUser"), {
  fallback: <Loader className="h-screen" />,
});
const AdminListIraOrgs = loadable(() => import("Components/Orgs/IraAdmin/AdminListIraOrgs"), {
  fallback: <Loader className="h-screen" />,
});
const AdminShowIraOrg = loadable(() => import("Components/Orgs/IraAdmin/AdminShowIraOrg"), {
  fallback: <Loader className="h-screen" />,
});

const OrgsList = loadable(() => import("Components/Orgs/List/OrgsList"), {
  fallback: <Loader className="h-screen" />,
});
const OrgManage = loadable(() => import("Routes/manage"), {
  fallback: <Loader className="h-screen" />,
});
const OrgShowAdmin = loadable(() => import("Components/Orgs/Show/OrgShowAdmin/OrgShowAdmin"), {
  fallback: <Loader className="h-screen" />,
});
const OrgShowArbiter = loadable(() => import("Components/Orgs/Show/OrgShowArbiter"), {
  fallback: <Loader className="h-screen" />,
});
const PostPayment = loadable(() => import("Components/PostPayment"), {
  fallback: <Loader className="h-screen" />,
});
const Support = loadable(() => import("Components/Support/Support"), {
  fallback: <Loader className="h-screen" />,
});
const TradingDeskAdmin = loadable(() => import("Components/TradingDesk/admin"), {
  fallback: <Loader className="h-screen" />,
});
const UsdActivity = loadable(() => import("Components/UsdActivity/UsdActivity"), {
  fallback: <Loader className="h-screen" />,
});
const SpendingWizard = loadable(() => import("Components/Transactions/Spending/SpendingWizard"), {
  fallback: <Loader className="h-screen" />,
});
const VaultNewContainer = loadable(() => import("Components/VaultsView/Create/VaultNewContainer"), {
  fallback: <Loader className="h-screen" />,
});
const VaultsListUser = loadable(() => import("Components/VaultsView/List/VaultsListUser"), {
  fallback: <Loader className="h-screen" />,
});
const VaultShowUser = loadable(() => import("Components/VaultsView/Show/VaultShowUser"), {
  fallback: <Loader className="h-screen" />,
});
const AccountKeyShowSigner = loadable(
  () => import("Components/account_keys/AccountKeyShowSigner"),
  { fallback: <Loader className="h-screen" /> }
);
const AccountKeysList = loadable(() => import("Components/account_keys/AccountKeysList"), {
  fallback: <Loader className="h-screen" />,
});
const PaymentMethods = loadable(() => import("Components/PaymentMethods/PaymentMethods"), {
  fallback: <Loader className="h-screen" />,
});
const ConfirmEmailChange = loadable(
  () => import("Components/credentials/ConfirmEmailChange/ConfirmEmailChange"),
  { fallback: <Loader className="h-screen" /> }
);
const ListOTPResets = loadable(() => import("Components/credentials/OTPResets/ListOTPResets"), {
  fallback: <Loader className="h-screen" />,
});
const OTPResetRecord = loadable(() => import("Components/credentials/OTPResets/OTPResetRecord"), {
  fallback: <Loader className="h-screen" />,
});
const ShowOTPResetAdmin = loadable(
  () => import("Components/credentials/OTPResets/ShowOTPResetAdmin"),
  { fallback: <Loader className="h-screen" /> }
);
const DocumentError = loadable(() => import("Components/errors/DocumentError"), {
  fallback: <Loader className="h-screen" />,
});
const NotFound = loadable(() => import("Components/errors/NotFound"), {
  fallback: <Loader className="h-screen" />,
});
const Features = loadable(() => import("Components/features/Features"), {
  fallback: <Loader className="h-screen" />,
});
const CollateralSaleRequests = loadable(() => import("Components/loans/CollateralSaleRequests"), {
  fallback: <Loader className="h-screen" />,
});
const LoanContractSigned = loadable(() => import("Components/loans/LoanContractSigned"), {
  fallback: <Loader className="h-screen" />,
});
const LoanPaymentsDue = loadable(() => import("Components/loans/LoanPaymentsDue"), {
  fallback: <Loader className="h-screen" />,
});
const LoanShowAdmin = loadable(() => import("Components/loans/LoanShowAdmin/index"), {
  fallback: <Loader className="h-screen" />,
});
const LoanSignContract = loadable(() => import("Components/loans/LoanSignContract"), {
  fallback: <Loader className="h-screen" />,
});
const LoansListAdmin = loadable(() => import("Components/loans/LoansListAdmin"), {
  fallback: <Loader className="h-screen" />,
});
const LoansListArbiter = loadable(() => import("Components/loans/LoansListArbiter"), {
  fallback: <Loader className="h-screen" />,
});
const LoansListClient = loadable(() => import("Components/loans/LoansListClient"), {
  fallback: <Loader className="h-screen" />,
});
const MarginCallInstructions = loadable(
  () => import("Components/payments/MarginCallInstructions"),
  { fallback: <Loader className="h-screen" /> }
);
const PaymentInstructions = loadable(() => import("Components/payments/PaymentInstructions"), {
  fallback: <Loader className="h-screen" />,
});
const RekeyShow = loadable(() => import("Components/rekeys/RekeyShow"), {
  fallback: <Loader className="h-screen" />,
});
const RekeyShowAdmin = loadable(() => import("Components/rekeys/RekeyShowAdmin"), {
  fallback: <Loader className="h-screen" />,
});
const RekeysList = loadable(() => import("Components/rekeys/RekeysList"), {
  fallback: <Loader className="h-screen" />,
});
const UserShowAdmin = loadable(() => import("Components/users/UserShowAdmin/UserShowAdmin"), {
  fallback: <Loader className="h-screen" />,
});
const UsersList = loadable(() => import("Components/users/UsersList"), {
  fallback: <Loader className="h-screen" />,
});
const VaultsListAdmin = loadable(() => import("Components/vaults/VaultsListAdmin"), {
  fallback: <Loader className="h-screen" />,
});
const VaultsListArbiter = loadable(() => import("Components/vaults/VaultsListArbiter"), {
  fallback: <Loader className="h-screen" />,
});
const VerificationsList = loadable(() => import("Components/verifications/VerificationsList"), {
  fallback: <Loader className="h-screen" />,
});
const BitcoinWallet = loadable(() => import("Components/wallet/BitcoinWallet"), {
  fallback: <Loader className="h-screen" />,
});
const OnboardBasicInfo = loadable(() => import("Routes/onboard/OnboardBasicInfo"), {
  fallback: <Loader className="h-screen" />,
});
const OnboardOrg = loadable(() => import("Routes/onboard/[uuid]/OnboardOrg"), {
  fallback: <Loader className="h-screen" />,
});
const Enable2FAPage = loadable(() => import("Routes/settings/(Security)/Enable2FA"), {
  fallback: <Loader className="h-screen" />,
});
const Settings = loadable(() => import("Routes/settings/Settings"), {
  fallback: <Loader className="h-screen" />,
});
const ChangeProposalList = loadable(() => import("Routes/change-proposals/OrgChangeProposals"), {
  fallback: <Loader className="h-screen" />,
});
const Connections = loadable(() => import("Routes/connections"), {
  fallback: <Loader className="h-screen" />,
});
const ConnectionDetails = loadable(() => import("Routes/connections/ConnectionDetailsPage"), {
  fallback: <Loader className="h-screen" />,
});
const IraRedirect = () => {
  const { orgUuid } = useParams<{ orgUuid: string }>();
  return <Navigate to={`/onboard/${orgUuid}`} />;
};
const InheritanceCheckout = loadable(() => import("Routes/inheritance"), {
  fallback: <Loader className="h-screen" />,
});
const SubscriptionManagement = loadable(
  () => import("Routes/subscriptions/SubscriptionManagement"),
  {
    fallback: <Loader className="h-screen" />,
  }
);
const Documents = loadable(() => import("Routes/documents"), {
  fallback: <Loader className="h-screen" />,
});
const IRABeneficiaries = loadable(() => import("Routes/ira-beneficiaries"), {
  fallback: <Loader className="h-screen" />,
});
const AccountKeyConfig = loadable(() => import("Routes/account-keys/[uuid]/config"), {
  fallback: <Loader className="h-screen" />,
});

const BaseApp = () => {
  const location = useLocation();
  const { pathname, search } = location;

  // Not logged in
  if (!window.TREFOIL_USER?.uuid) {
    if (
      !pathname.startsWith("/login") &&
      !pathname.startsWith("/logout") &&
      !pathname.startsWith("/sign_up") &&
      !pathname.startsWith("/2fa") &&
      !pathname.startsWith("/credentials")
    ) {
      if (pathname !== "/") {
        const searchParams = search || "";
        return <Navigate to={`/login?next=${encodeURIComponent(pathname + searchParams)}`} />;
      } else {
        return <Navigate to="/login" />;
      }
    }

    return <PublicApp />;

    // If authenticated but on auth route
  } else if (["/login", "/sign_up"].some(route => pathname.startsWith(route))) {
    return <Navigate to="/home" />;
  }

  // Logged in on logged-in route
  return <UserApp />;
};

export const routes = () => (
  <Route path="/" element={<BaseApp />}>
    <Route path="onboard">
      <Route index element={<OnboardBasicInfo />} />
      <Route path=":uuid" element={<OnboardOrg />} />
    </Route>
    <Route path="ira-beneficiaries" element={<IRABeneficiaries />} />
    <Route path="/inheritance" element={<InheritanceCheckout />} />
    <Route path="invoices">
      <Route path=":uuid">
        <Route index element={<InvoiceStepper />} />
      </Route>
    </Route>
    <Route path="login" element={<Login />} />
    <Route path="logout" element={<Logout />} />
    <Route path="sign_up" element={<Signup />} />
    <Route path="post-payment" element={<Protect Component={PostPayment} lockForPayment />} />
    <Route path="sign_ups">
      <Route path=":signup_token">
        <Route index element={<Signup />} />
        <Route path="confirm">
          <Route index element={<SignupConfirm />} />
        </Route>
      </Route>
    </Route>
    <Route path="credentials">
      <Route path="password">
        <Route path="reset">
          <Route path="request" element={<RequestPasswordReset />} />
          <Route path=":token" element={<ConfirmPasswordReset />} />
        </Route>
      </Route>
      <Route path="email">
        <Route path="change">
          <Route path=":token" element={<ConfirmEmailChange />} />
        </Route>
      </Route>
    </Route>
    <Route
      path="home"
      element={
        <Protect
          Component={Dashboard}
          lockForPayment
          // Block dashboard for admins
          requireOrgTypes={["arbiter", "client", "delegate"]}
          alternatives={{
            requireOrgTypes: () => <Navigate to="/settings" />,
            requireIraConsent: account => (
              <div className="flex h-screen w-full items-center justify-center">
                <IRAConsentDash uuid={account.currentOrg.uuid} />
              </div>
            ),
          }}
          requireIraConsent
        />
      }
    />

    <Route path="settings" element={<Settings />} />
    <Route path="documents" element={<Documents />} />
    <Route path="manage" element={<OrgManage />} />
    <Route path="payment-methods" element={<PaymentMethods />} />
    <Route path="users">
      <Route index element={<Protect Component={UsersList} requireOrgTypes={["unchained"]} />} />
      <Route path=":uuid">
        <Route
          index
          element={<Protect Component={UserShowAdmin} requireOrgTypes={["unchained"]} />}
        />
      </Route>
    </Route>
    <Route path="admin">
      <Route path="orgs">
        <Route path="trusts">
          <Route
            index
            element={<Protect Component={AdminListTrustOrgs} requireOrgTypes={["unchained"]} />}
          />
          <Route path=":uuid">
            <Route
              index
              element={<Protect Component={AdminShowTrust} requireOrgTypes={["unchained"]} />}
            />
          </Route>
        </Route>
      </Route>
    </Route>

    <Route path="orgs">
      <Route
        index
        element={<Protect Component={OrgsList} requireOrgTypes={["unchained", "arbiter"]} />}
      />
      <Route path="ira">
        <Route path=":orgUuid">
          <Route index element={<IraRedirect />} />
          <Route
            path="admin"
            element={<Protect Component={AdminShowIraOrg} requireOrgTypes={["unchained"]} />}
          />
        </Route>
        <Route
          path="admin"
          element={<Protect Component={AdminListIraOrgs} requireOrgTypes={["unchained"]} />}
        />
      </Route>
      <Route path="delegated-custody">
        <Route
          path="clients"
          element={
            <Protect
              Component={DelegatedCustodyClientList}
              requireOrgTypes={["unchained", "delegate"]}
            />
          }
        />
      </Route>

      <Route path=":uuid">
        <Route
          index
          element={
            <Protect Component={OrgShowArbiter} requireOrgTypes={["unchained", "arbiter"]} />
          }
        />
        <Route
          path="admin"
          element={<Protect Component={OrgShowAdmin} requireOrgTypes={["unchained"]} />}
        />
        <Route path="change-proposals">
          <Route index element={<ChangeProposalList />} />
          <Route path=":groupUuid">
            <Route
              index
              element={
                <LoadingContextProvider>
                  <ChangeProposalGroup />
                </LoadingContextProvider>
              }
            />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="loans">
      <Route
        index
        element={
          <Protect
            Component={LoansListClient}
            requireAccountTypes={["individual", "business", "trust"]}
            lockForPayment
          />
        }
      />
      <Route
        path="admin"
        element={<Protect Component={LoansListAdmin} requireOrgTypes={["unchained"]} />}
      />
      <Route
        path="new"
        element={
          <Protect
            Component={LoanNewContainer}
            requireAccountTypes={["individual", "business", "trust"]}
            lockForPayment
          />
        }
      />
      <Route path="payments">
        <Route path="due" element={<LoanPaymentsDue />} />
      </Route>
      <Route
        path="collateral-sale-requests"
        element={<Protect Component={CollateralSaleRequests} lockForPayment />}
      />
      <Route path=":uuid">
        <Route index element={<Protect Component={LoanShowUser} lockForPayment />} />
        <Route path="redemptions">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="liquidations">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="closings">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="sweeps">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route
          path="admin"
          element={<Protect Component={LoanShowAdmin} requireOrgTypes={["unchained"]} />}
        />
        <Route path="contract">
          <Route path="sign" element={<LoanSignContract />} />
          <Route path="signed" element={<LoanContractSigned />} />
        </Route>
      </Route>
    </Route>
    <Route path="vaults">
      <Route
        index
        element={<Protect Component={VaultsListUser} lockForPayment requireIraConsent />}
      />
      <Route
        path="admin"
        element={<Protect Component={VaultsListAdmin} requireOrgTypes={["unchained"]} />}
      />
      <Route
        path="new"
        element={<Protect Component={VaultNewContainer} lockForPayment requireIraConsent />}
      />
      <Route path=":uuid">
        <Route
          index
          element={<Protect Component={VaultShowUser} lockForPayment requireIraConsent />}
        />
        <Route path="transactions">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="sales">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="sweeps">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route path="settlements">
          <Route
            path=":operation_uuid"
            element={<Protect Component={SpendingWizard} lockForPayment />}
          />
        </Route>
        <Route
          path="admin"
          element={<Protect Component={VaultShowUser} requireOrgTypes={["unchained"]} />}
        />
      </Route>
    </Route>
    <Route path="arbiter">
      <Route path="loans">
        <Route index element={<LoansListArbiter />} />
        <Route
          path=":uuid"
          element={<Protect Component={LoanShowUser} lockForPayment requireIraConsent />}
        />
      </Route>
      <Route path="vaults">
        <Route index element={<VaultsListArbiter />} />
        <Route
          path=":uuid"
          element={<Protect Component={VaultShowUser} lockForPayment requireIraConsent />}
        />
      </Route>
    </Route>
    <Route path="2fa">
      <Route path="enable" element={<Enable2FAPage />} />
      <Route path="resets">
        <Route index element={<ListOTPResets />} />
        <Route path=":uuid_or_token">
          <Route index element={<OTPResetRecord />} />
          <Route path="admin" element={<ShowOTPResetAdmin />} />
        </Route>
      </Route>
    </Route>
    <Route path="wallet">
      <Route index element={<Navigate to="BTC" />} />
      <Route path="BTC" element={<Protect Component={BitcoinWallet} lockForPayment />} />
    </Route>
    <Route path="account_keys">
      <Route index element={<Protect Component={AccountKeysList} lockForPayment />} />
      <Route path="new" element={<Protect Component={AccountKeyNewWizard} lockForPayment />} />
      <Route path=":uuid">
        <Route index element={<Protect Component={AccountKeyShowSigner} lockForPayment />} />
        <Route path="rekey">
          <Route index element={<Protect Component={RekeyShow} lockForPayment />} />
          <Route
            path="admin"
            element={<Protect Component={RekeyShowAdmin} requireOrgTypes={["unchained"]} />}
          />
        </Route>
        <Route
          path="config"
          element={<Protect Component={AccountKeyConfig} requireOrgTypes={["unchained"]} />}
        />
      </Route>
    </Route>
    <Route
      path="rekeys"
      element={<Protect Component={RekeysList} requireOrgTypes={["unchained"]} />}
    />
    <Route path="payment_instructions" element={<PaymentInstructions />} />
    <Route
      path="margin_calls"
      element={<Protect Component={MarginCallInstructions} requireOrgTypes={["unchained"]} />}
    />
    <Route path="btc_transaction_requests">
      <Route index element={<BtcTransactionRequestsList />} />
    </Route>
    <Route
      path="features"
      element={<Protect Component={Features} requireOrgTypes={["unchained"]} />}
    />
    <Route path="connections">
      <Route index element={<Connections />} />
      <Route path="connection/:connectionUuid" element={<ConnectionDetails />} />
    </Route>
    <Route
      path="verifications"
      element={<Protect Component={VerificationsList} requireOrgTypes={["unchained"]} />}
    />
    <Route path="document">
      <Route path="error" element={<DocumentError />} />
    </Route>
    <Route path="support">
      <Route index element={<Support />} />
    </Route>
    <Route
      path="trading"
      element={
        <Protect
          Component={TradingDeskAdmin}
          requireOrgTypes={["unchained"]}
          requireAction="view_trading_info"
        />
      }
    />
    <Route
      path="usd_activity"
      element={
        <Protect
          Component={UsdActivity}
          requireOrgTypes={["unchained"]}
          requireAction="view_usd_activity"
        />
      }
    />
    <Route path="subscriptions" element={<SubscriptionManagement />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

export default () => {
  return <Routes>{routes()}</Routes>;
};
